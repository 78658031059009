window.addEventListener('load', function () {

    var organizationProperty = new Vue({
        el: '#formOrganizationProperty',
        data: {
            sent: false,
            errors: '',
            formName: {
                name: 'formOrganizationProperty',
                description: 'Заявка на страхование имущества юридических лиц'
            },
            form: {
                organizationName: {
                    "f-label": "Наименование компании *", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": true,
                    "f-style": {}
                },
                delegateName: {
                    "f-label": "ФИО представителя",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                phone: {
                    "f-label": "Введите номер телефона для обратной связи *",
                    "f-type": "phone",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {},
                    "f-regexp": "^\\+7\\(\\d{3}\\) \\d{3}-\\d{2}-\\d{2}$"
                },
                email: {
                    "f-label": "Введите эл.почту для обратной связи *",
                    "f-type": "email",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {}
                },
                propertyKind: {
                    "f-label": "Вид имущества",
                    "f-type": "select",
                    "f-condition": true,
                    "f-value": "",
                    "f-dic": "propertyKindDic",
                    "f-plain-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                propertyKindComment: {
                    "f-label": "Дополнительная информация",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-placeholder": "Добавьте дополнительное описание при необходимости...",
                    "f-required": false,
                    "f-style": {}
                },
                property: {
                    "f-label": "Имущество",
                    "f-type": "select",
                    "f-condition": true,
                    "f-value": "",
                    "f-dic": "propertyDic",
                    "f-plain-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                propertyComment: {
                    "f-label": "Дополнительная информация",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-placeholder": "Добавьте дополнительное описание при необходимости...",
                    "f-required": false,
                    "f-style": {}
                },
                totalCost: {
                    "f-label": "Общая стоимость имущества (млн.рублей)",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                location: {
                    "f-label": "Месторасположение имущества (указать город)", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                companyAge: {
                    "f-label": "Сколько лет Ваша компания осуществляет свою деятельность", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                businessType: {
                    "f-label": "Вид деятельности Вашей компании", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                needPublicLiability: {
                    "f-label": "Требуется страхование гражданской ответственности",
                    "f-type": "switch",
                    "f-condition": true,
                    "f-value": false,
                    "f-required": false,
                    "f-style": {}
                },
                publicLiabilityLimit: {
                    "f-label": "Лимит ответственности (млн.рублей)",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                needBusinessPause: {
                    "f-label": "Требуется страхование убытков от перерыва в производстве",
                    "f-type": "switch",
                    "f-condition": true,
                    "f-value": false,
                    "f-required": false,
                    "f-style": {}
                }
            },
            dictionaries: {
                propertyKindDic: [
                    {name: "building", description: "здания, сооружения"},
                    {name: "equipment", description: "оборудование"},
                    {name: "special-equipment", description: "спецтехника"},
                    {name: "other", description: "иное"}
                ],
                propertyDic: [
                    {name: "ownership", description: "является собственностью вашей компании"},
                    {name: "rent", description: "арендованное имущество"},
                    {name: "leasing", description: "лизинговое имущество"},
                    {name: "other", description: "иное"}
                ]
            }
        },
        methods: {
            switchId(fName) {
                return this.formName.name + '-' + fName;
            },
            refreshForm() {
                for (var key in this.form) {
                    this.form[key]['f-value'] = '';
                }
            },
            onSubmit(evt) {
                evt.preventDefault();

                this.errors = "";
                let errorsCnt = validateForm(this.form);
                if (errorsCnt > 0) {
                    this.errors = "Необходимо корректно заполнить все поля, отмеченные звездочкой (*)";
                }

                if (errorsCnt == 0) {

                    let mailMessage = constructMailMessage(this.form, this.formName.description);

                    const emailOptions = {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                        data: {msg: mailMessage},
                        url: '/local/form/submit-msg-form.php',
                    };

                    this.$axios(emailOptions)
                        .then(res => { 
                            // console.log('msg was sent ' + mailMessage);
                            this.sent = true; 
                            this.refreshForm();
                            $.fancybox.close({src: '#' + this.form.name});
                            $.fancybox.open('<div  id="thanks" class="popup ths"> <div class="ths-box"> <div class="ths-marg"> <p class="ths-t1">заявка принята</p> <p class="ths-t2">Мы свяжемся с вами <br>в ближайшее время</p> </div> </div> </div>');
                        })
                        .catch(function (error) {
                            console.log(error);
                            $.fancybox.open('<div class="message"><h2>Ошибка</h2></div>');
                        });
                }
            }            
        },
        computed: {
            watchPropertyKind() {
                return this.form.propertyKind['f-value'];
            },
            watchProperty() {
                return this.form.property['f-value'];
            },
            watchNeedPublicLiability() {
                return this.form.needPublicLiability['f-value'];
            }
        },
        watch: {
            watchPropertyKind: function() {
                var vm = this;
                this.form.propertyKindComment['f-value'] = '';
                this.form.propertyKindComment['f-condition'] = this.form.propertyKind['f-value'] !== '';
                try {
                    this.form.propertyKind['f-plain-value'] = this.dictionaries[this.form.propertyKind['f-dic']] ? 
                        this.dictionaries[this.form.propertyKind['f-dic']].find(function(dic){
                            return dic.name === vm.form.propertyKind['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            },
            watchProperty: function() {
                var vm = this;
                this.form.propertyComment['f-value'] = '';
                this.form.propertyComment['f-condition'] = this.form.property['f-value'] !== '';
                try {
                    this.form.property['f-plain-value'] = this.dictionaries[this.form.property['f-dic']] ? 
                        this.dictionaries[this.form.property['f-dic']].find(function(dic){
                            return dic.name === vm.form.property['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            },
            watchNeedPublicLiability: function() {
                this.form.publicLiabilityLimit['f-value'] = '';
                this.form.publicLiabilityLimit['f-condition'] = this.form.needPublicLiability['f-value'];
            }
        }
    });
})